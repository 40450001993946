<template>
    <Panel ref="userPanel" :toggleable="true" class="nopad" :id="configFuncionario.idFuncionario">
        <template #header>
            <div class="w-full py-3 text-xl font-medium">
                {{ configFuncionario.nomeFuncionario }}
            </div>
        </template>
        <template #default>
            <TabelaReservaExamesAtendidos
                :configFuncionario="configFuncionario"
                :configFuncionariosOriginal="configFuncionariosOriginal"
                :configFuncionarioIndex="configFuncionarioIndex"
                :agendamento="formData"
                :minDate="minDate"
                :maxDate="maxDate"
            />
            <DataTable :value="configFuncionario.examesNaoAtendidos">
                <Column v-for="col of examesPrestadorColumns" :field="col.field" :key="col.field" :style="col.style" header-class="hidden">
                    <template #body="slotProps">
                        <template v-if="col.field === 'prestador'">
                            <DropdownPrestadorCliente
                                :input-style="{ width: '0.1rem' }"
                                class="w-full"
                                id="prestador"
                                v-model="slotProps.data.prestador"
                                :autoLoad="true"
                                :disabled="slotProps.data.exameParticular"
                                :filtrosExtras="filtrosExtrasPrestador"
                                :idExame="slotProps.data.id"
                                :idCidade="formData.cidade?.id"
                                :idCliente="formData.funcionarios?.map((e) => e.customerId)"
                                :groupKey="slotProps.index"
                                @update:model-value="atualizarValorAntecedencia(slotProps.data, slotProps.index)"
                                :class="{ 'p-invalid': configFuncionario.submitted && !slotProps.data.prestador && !slotProps.data.exameParticular }"
                            />
                        </template>
                        <template v-else-if="col.field === 'procedimentos'">
                            <span>{{ getNomesProcedimentos(slotProps.data) }}</span>
                        </template>
                        <template v-else-if="col.field === 'data'">
                            <Calendar
                                :max-date="getMaxDateNaoAtendido()"
                                v-model="slotProps.data.data"
                                :disabled="!slotProps.data.prestador && !slotProps.data.exameParticular"
                                :disabledDays="form.disabledDays"
                                :class="{ 'p-invalid': configFuncionario.submitted && !slotProps.data.data }"
                            />
                        </template>
                        <template v-else-if="col.field === 'hora'">
                            <span class="flex justify-content-between gap-3">
                                <InputMask
                                    style="width: 50%"
                                    mask="99:99"
                                    v-model="slotProps.data.hora"
                                    :disabled="!slotProps.data.prestador"
                                    :class="{ 'p-invalid': configFuncionario.submitted && !slotProps.data.hora && !slotProps.data.exameParticular }"
                                />
                                <span class="flex align-items-center gap-2">
                                    <Checkbox
                                        :id="`particular${slotProps.index}`"
                                        v-model="slotProps.data.exameParticular"
                                        binary
                                        @input="(value) => handleParticular(value, slotProps.index)"
                                    />
                                    <label :for="`particular${slotProps.index}`"> Particular</label>
                                </span>
                            </span>
                        </template>
                        <template v-else>
                            <span>{{ slotProps.data[col.field] }}</span>
                        </template>
                    </template>
                </Column>
                <template #footer>
                    <div class="flex justify-content-end gap-4">
                        <ConfirmDialog :group="`confirmarDirecionamento${configFuncionario.idFuncionario}`" />
                        <Button
                            label="Direcionar para um atendente"
                            class="p-button-text px-5 font-medium"
                            @click="confirmarDirecionamento(configFuncionario.idFuncionario)"
                            :loading="configFuncionario.loading"
                        />
                        <Button
                            label="Agendar"
                            class="px-5"
                            @click="agendarFuncionario(configFuncionario.idFuncionario)"
                            :loading="configFuncionario.loading"
                        />
                    </div>
                </template>
            </DataTable>
        </template>
    </Panel>
    <DialogAlterarPeriodoAgendamento ref="dialogAlterarPeriodo" v-model:formData="form" @periodoAlterado="$emit('periodoAlterado', $event)" />
    <Toast />
</template>

<script setup>
import DropdownPrestadorCliente from '../../../../../prestadores/components/DropdownPrestadorCliente.vue';
import { computed, defineEmits, defineProps, inject, nextTick, ref } from 'vue';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import AgendamentoService from '../../../../../gestao_medica/agendamentos/services/AgendamentoService';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { getClientBase } from '@/services/http';
import DialogAlterarPeriodoAgendamento from './DialogAlterarPeriodoAgendamento.vue';
import TabelaReservaExamesAtendidos from '../../../dialog-reserva-horario/components/TabelaReserva/components/TabelaReservaExamesAtendidos.vue';

const toast = useToast();
const agendamentoService = new AgendamentoService(toast);
const salvarAgendamento = inject('salvarAgendamento');
const removePendenciaSeSolicitado = inject('removePendenciaSeSolicitado');
const confirm = useConfirm();

const emit = defineEmits(['update:formData', 'periodoAlterado']);

const props = defineProps({
    formData: {
        type: Object,
        required: true
    },
    configFuncionario: {
        type: Object,
        required: true
    },
    configFuncionarioIndex: {
        type: Number,
        required: true
    },
    configFuncionariosOriginal: {
        type: Array,
        required: true
    }
});

const form = computed({
    get() {
        return props.formData;
    },
    set(value) {
        emit('update:formData', value);
    }
});

const configFuncionarios = computed({
    get() {
        return props.formData.configFuncionarios || [];
    },
    set(value) {
        form.value.configFuncionarios = value;
    }
});

const minDate = computed(() => {
    return new Date(form.value?.intervaloSugerido?.[0]);
});

const maxDate = computed(() => {
    return new Date(form.value?.intervaloSugerido?.[1]);
});

function getMaxDateNaoAtendido() {
    const dataClinico = props.configFuncionario?.examesAtendidos?.at(-1)?.data;
    if (!dataClinico) return maxDate.value;
    return dayjs(dataClinico).toDate();
}

const filtrosExtrasPrestador = computed(() => {
    return {
        agruparPrestadores: true
    };
});

const userPanel = ref(null);

const examesPrestadorColumns = [
    {
        field: 'procedimentos',
        header: 'Procedimentos',
        style: 'width: 27%'
    },
    {
        field: 'prestador',
        header: 'Onde / Prestador',
        style: 'width: 28%'
    },
    {
        field: 'antecedencia',
        header: 'Antecedência',
        style: 'width: 10%'
    },
    {
        field: 'data',
        header: '<i class="pi pi-calendar font-semibold"></i> Data',
        style: 'width: 10%; min-width: 105px'
    },
    {
        field: 'hora',
        header: '<i class="pi pi-clock font-semibold"></i> Hora',
        style: 'width: 25%; min-width: 212px'
    }
];

function getNomesProcedimentos(procedimentos) {
    const procedimentosArray = Array.isArray(procedimentos) ? procedimentos : [procedimentos];
    return procedimentosArray.map(({ name }) => name).join('; ');
}

async function confirmarDirecionamento(idFuncionario) {
    confirm.require({
        group: `confirmarDirecionamento${idFuncionario}`,
        message: 'Deseja realmente direcionar o funcionário para um atendente?',
        header: 'Direcionar para atendente',
        acceptLabel: 'Sim',
        rejectLabel: 'Cancelar',
        rejectClass: 'p-button-outlined mr-4',
        accept: () => direcionarFuncionarioParaAtendente(idFuncionario)
    });
}

function iniciarLoadingFuncionario(idFuncionario) {
    configFuncionarios.value = configFuncionarios.value.map((funcionario) => {
        if (funcionario.idFuncionario === idFuncionario) {
            funcionario.loading = true;
            funcionario.submitted = true;
        }
        return funcionario;
    });
}

function finalizarLoadingFuncionario(idFuncionario) {
    configFuncionarios.value = configFuncionarios.value.map((funcionario) => {
        if (funcionario.idFuncionario === idFuncionario) {
            delete funcionario.loading;
        }
        return funcionario;
    });
}

async function direcionarFuncionarioParaAtendente(idFuncionario) {
    iniciarLoadingFuncionario(idFuncionario);
    const funcionario = form.value.funcionarios.find((funcionario) => funcionario.id === idFuncionario);
    funcionario.direcionarParaAtendente = true;
    try {
        const success = await salvarAgendamento(funcionario);
        retirarFuncionarioDaTela(success, idFuncionario);
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao direcionar',
            detail: 'Não foi possível direcionar o agendamento para um atendente. Tente novamente.',
            life: 3000
        });
    }
    finalizarLoadingFuncionario(idFuncionario);
}

async function agendarFuncionario(idFuncionario) {
    iniciarLoadingFuncionario(idFuncionario);
    validarConfigFuncionario();
    if (configInvalida.value) {
        toast.add({
            severity: 'warn',
            summary: 'Erro',
            detail: 'Preencha todos os campos corretamente',
            life: 3000
        });
        await nextTick(() => {
            configInvalida.value = false;
        });
        finalizarLoadingFuncionario(idFuncionario);
        return;
    }
    const horariosValidos = await validarHorariosDisponiveisNaAgenda(idFuncionario);
    if (!horariosValidos) {
        toast.add({
            severity: 'info',
            summary: 'Um dos períodos selecionados não está mais disponível. Por favor, selecione outro período.',
            life: 8000
        });
        await nextTick(() => {
            configInvalida.value = false;
        });
        finalizarLoadingFuncionario(idFuncionario);
        return;
    }
    const funcionario = form.value.funcionarios.find((p) => p.id === idFuncionario);
    const configFuncionario = form.value.configFuncionarios.find((p) => p.idFuncionario === funcionario.id);

    if (configFuncionario) {
        const arrayExamesAtendidos = configFuncionario.examesAtendidos?.map((ambiente) => {
            return {
                prestadorId: ambiente.prestador.id,
                examesIds: ambiente.procedimentos?.map((proc) => proc.id),
                diasAntecedencia: ambiente.antecedencia,
                prestadorInformado: true,
                horarioSemPrestador: null,
                itens: ambiente.hora?.itens,
                exameParticular: ambiente?.exameParticular
            };
        });

        const arrayExamesNaoAtendidos = configFuncionario.examesNaoAtendidos?.length
            ? configFuncionario.examesNaoAtendidos?.map((exame) => {
                  const [hora, minuto] = exame.hora.split(':');
                  const horarioSemPrestador = moment(exame.data).add(hora, 'hours').add(minuto, 'minutes');
                  return {
                      prestadorId: exame.prestador?.id,
                      examesIds: [exame.id],
                      diasAntecedencia: exame.antecedencia,
                      prestadorInformado: false,
                      horarioSemPrestador,
                      itens: exame.hora?.itens,
                      exameParticular: exame?.exameParticular
                  };
              })
            : [];

        funcionario.procedimentosFromSoc = [...arrayExamesAtendidos, ...arrayExamesNaoAtendidos];
    }

    const success = await agendamentoService.save(form.value, funcionario);
    await removePendenciaSeSolicitado?.(funcionario);
    retirarFuncionarioDaTela(success, idFuncionario);
    finalizarLoadingFuncionario(idFuncionario);
}

async function atualizarValorAntecedencia(exameNaoAtendido, index) {
    exameNaoAtendido.data = undefined;
    exameNaoAtendido.hora = undefined;
    if (!exameNaoAtendido?.prestador) {
        exameNaoAtendido.antecedencia = props.configFuncionariosOriginal[props.configFuncionarioIndex].examesNaoAtendidos[index].antecedencia;
        return;
    }
    const { data } = await getClientBase().get(
        `prestador-exames/find-by-prestador-e-procedimento/${exameNaoAtendido.prestador.id}/${exameNaoAtendido.id}`
    );
    exameNaoAtendido.antecedencia = data.diasAntecedencia;
}

function handleParticular(particularValue, index) {
    if (particularValue) {
        delete configFuncionarios.value[props.configFuncionarioIndex].examesNaoAtendidos[index].prestador;
        delete configFuncionarios.value[props.configFuncionarioIndex].examesNaoAtendidos[index].hora;
    }
}

function retirarFuncionarioDaTela(success, idFuncionario) {
    if (success) {
        form.value.funcionarios = form.value.funcionarios.filter((funcionario) => funcionario.id !== idFuncionario);
        configFuncionarios.value = configFuncionarios.value.filter((funcionario) => funcionario.idFuncionario !== idFuncionario);
    }
}

const configInvalida = ref(false);

function validarConfigFuncionario() {
    if (props.configFuncionario.examesAtendidos.length) {
        const examesAtendidosValidos = props.configFuncionario.examesAtendidos.every((exame) => {
            return exame.data && exame.hora;
        });
        if (!examesAtendidosValidos) {
            configInvalida.value = true;
        }
    }
    if (props.configFuncionario.examesNaoAtendidos.length) {
        const examesNaoAtendidosValidos = props.configFuncionario.examesNaoAtendidos.every((exame) => {
            return (exame.prestador && exame.data && exame.hora) || (exame.exameParticular && exame.data);
        });
        if (!examesNaoAtendidosValidos) {
            configInvalida.value = true;
        }
    }
}

async function validarHorariosDisponiveisNaAgenda(idFuncionario) {
    try {
        const configFuncionario = form.value.configFuncionarios.find((p) => p.idFuncionario === idFuncionario);
        let horarioDisponivel = true;
        for (const exame of configFuncionario.examesAtendidos) {
            const dto = {
                prestadorId: exame.prestador.id,
                funcionarioId: props.configFuncionario?.idFuncionario,
                ambienteId: exame?.ambienteID,
                examesIds: exame?.procedimentos.map((p) => p.id),
                dataInicial: exame?.data,
                dataFinal: exame?.data,
                periodo: props.formData?.periodo,
                isPCD:
                    props.formData.funcionarios.find((funcionario) => funcionario.id === props.configFuncionario?.idFuncionario).pcd ||
                    procedimentoLaudoPCD(),
                tipoExameId: props.formData.tipoExame.id
            };
            const { data } = await getClientBase().post('agendamentos/new-faixas-disponiveis', dto);
            horarioDisponivel = data.some(
                (horario) => horario.horarioChegada === exame.hora.horarioChegada && horario.horarioSaida === exame.hora.horarioSaida
            );

            if (!horarioDisponivel) {
                exame.data = undefined;
                exame.hora = undefined;
                break;
            }
        }
        return horarioDisponivel;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message || error?.message,
            life: 3000
        });
    }
}

function procedimentoLaudoPCD() {
    const procedimentos = props.formData.funcionarios.find((funcionario) => funcionario.id === props.configFuncionario.idFuncionario)?.procedimentos;
    return !!procedimentos?.some((p) => p.pcd);
}

const dialogAlterarPeriodo = ref(null);
</script>

<style lang="scss" scoped>
:deep(.p-message-wrapper) {
    width: 100%;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: start;
}
</style>
